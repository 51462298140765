<template>
  <div>
    <div class="container-fluid py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <form class="bg-light">
              <div class="col-sm-12 p-5">
                <div class="row">
                  <div class="col-sm-6 form-group">
                    <label>{{ $t("USERNAME") }}</label>
                    <input
                      type="text"
                      :placeholder="$t('ENTER_USERNAME_HERE')"
                      class="form-control"
                      required
                      v-model="username"
                    />
                  </div>
                  <div class="col-sm-6 form-group">
                    <label>{{ $t("EMAIL") }}</label>
                    <input
                      type="email"
                      :placeholder="$t('ENTER_EMAIL_NAME_HERE')"
                      class="form-control"
                      required
                      v-model="email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 form-group">
                    <label>{{ $t("PASSWORD") }}</label>
                    <input
                      type="password"
                      :placeholder="$t('ENTER_PASSWORD_HERE')"
                      class="form-control"
                      required
                      v-model="password"
                    />
                  </div>
                  <div class="col-sm-6 form-group">
                    <label>{{ $t("PASSWORD_CONFIRM") }}</label>
                    <input
                      type="password"
                      :placeholder="$t('ENTER_PASSWORD_CONFIRM_HERE')"
                      class="form-control"
                      required
                      v-model="password_confirm"
                    />
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label>Address</label>
                  <textarea
                    placeholder="Enter Address Here.."
                    rows="3"
                    class="form-control"
                  ></textarea>
                </div>
                <div class="row">
                  <div class="col-sm-4 form-group">
                    <label>City</label>
                    <input
                      type="text"
                      placeholder="Enter City Name Here.."
                      class="form-control"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>State</label>
                    <input
                      type="text"
                      placeholder="Enter State Name Here.."
                      class="form-control"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Zip</label>
                    <input
                      type="text"
                      placeholder="Enter Zip Code Here.."
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      placeholder="Enter Designation Here.."
                      class="form-control"
                    />
                  </div>
                  <div class="col-sm-6 form-group">
                    <label>Company</label>
                    <input
                      type="text"
                      placeholder="Enter Company Name Here.."
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    placeholder="Enter Phone Number Here.."
                    class="form-control"
                  />
                </div> -->

                <!-- <div class="form-group">
                  <label>Website</label>
                  <input
                    type="text"
                    placeholder="Enter Website Name Here.."
                    class="form-control"
                  />
                </div> -->
                <button
                  type="button"
                  class="btn btn-md btn-primary float-right mb-4"
                  @click="submit"
                >
                  {{ $t("SUBMIT_REGISTER") }}
                </button>
              </div>
            </form>
          </div>
          <!-- <div class="bg-light p-2 col-lg-3">
            <MostPlayed />
          </div> -->
        </div>
      </div>
    </div>

    <!-- card.// -->
  </div>
</template>

<script>
import Countrise from "./components/Countrise.vue";
import MostPlayed from "./components2/MostPlayed.vue";

export default {
  components: { Countrise, MostPlayed },
  mounted() {},
  data() {
    return { username: "", password: "", password_confirm: "", email: "" };
  },
  methods: {
    mailFormat(inputText) {
      var mailFormat = /\S+@\S+\.\S+/;
      if (inputText.match(mailFormat)) {
        //  alert("Valid address!");
        return true;
      } else {
        // alert("Invalid address!");
        return false;
      }
    },
    submit() {
      console.log(this.username, this.password, this.password_confirm);
      if (this.username === "" || this.username === undefined) {
        this.$fire({
          // title:this.$t("PASSWORD_DO_NOT_MATCH"),
          text: this.$t("USERNAME_EMPTY"),
          type: "error",
          timer: 3000,
        }).then((r) => {
          console.log(r.value);
        });
      } else if (this.email === "" || this.email === undefined) {
        this.$fire({
          // title:this.$t("PASSWORD_DO_NOT_MATCH"),
          text: this.$t("EMAIL_EMPTY"),
          type: "error",
          timer: 3000,
        }).then((r) => {
          console.log(r.value);
        });
      } else if (!this.mailFormat(this.email)) {
        this.$fire({
          // title:this.$t("PASSWORD_DO_NOT_MATCH"),
          text: this.$t("INVALID_EMAIL"),
          type: "error",
          timer: 3000,
        }).then((r) => {
          console.log(r.value);
        });
      } else if (this.password === "" || this.password === undefined) {
        this.$fire({
          // title:this.$t("PASSWORD_DO_NOT_MATCH"),
          text: this.$t("PASSWORD_EMPTY"),
          type: "error",
          timer: 3000,
        }).then((r) => {
          console.log(r.value);
        });
      } else if (
        this.password_confirm === "" ||
        this.password_confirm === undefined
      ) {
        this.$fire({
          // title:this.$t("PASSWORD_DO_NOT_MATCH"),
          text: this.$t("PASSWORD_CONFIRM_EMPTY"),
          type: "error",
          timer: 3000,
        }).then((r) => {
          console.log(r.value);
        });
      } else if (this.password != this.password_confirm) {
        this.$fire({
          // title:this.$t("PASSWORD_DO_NOT_MATCH"),
          text: this.$t("PASSWORD_DO_NOT_MATCH"),
          type: "error",
          timer: 3000,
        }).then((r) => {
          console.log(r.value);
        });
      } else {
        this.$http
          .post(`${process.env.VUE_APP_BACKEND_API_URL}/auth/register`, {
            username: this.username,
            password: this.password,
            email: this.email,
          })
          .then(({ data }) => {
            console.log("register", data);
            this.$fire({
              // title:this.$t("PASSWORD_DO_NOT_MATCH"),
              text: this.$t("SUCCESS"),
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          })
          .catch((err) => {
            return;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
</style>
